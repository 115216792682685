.btn,
.fc-unthemed .fc-button {
  display: inline-block;
  font-weight: 400;
  color: #3e4676;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.25rem 0.7rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .fc-unthemed .fc-button {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover,
.fc-unthemed .fc-button:hover {
  color: #3e4676;
  text-decoration: none;
}
.btn:focus,
.fc-unthemed .fc-button:focus,
.btn.focus,
.fc-unthemed .focus.fc-button {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(104, 122, 232, 0.25);
}
.btn.disabled,
.fc-unthemed .disabled.fc-button,
.btn:disabled,
.fc-unthemed .fc-button:disabled {
  opacity: 0.65;
}

a.btn.disabled,
.fc-unthemed a.disabled.fc-button,
fieldset:disabled a.btn,
fieldset:disabled .fc-unthemed a.fc-button,
.fc-unthemed fieldset:disabled a.fc-button {
  pointer-events: none;
}

.btn-primary,
.fc-unthemed .fc-button {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.btn-primary:hover,
.fc-unthemed .fc-button:hover {
  color: $white;
  background-color: #475de3;
  border-color: #3c53e1;
}
.btn-primary:focus,
.fc-unthemed .fc-button:focus,
.btn-primary.focus,
.fc-unthemed .focus.fc-button {
  box-shadow: 0 0 0 0.2rem rgba(127, 142, 235, 0.5);
}
.btn-primary.disabled,
.fc-unthemed .disabled.fc-button,
.btn-primary:disabled,
.fc-unthemed .fc-button:disabled {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.btn-primary:not(:disabled):not(.disabled):active,
.fc-unthemed .fc-button:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.fc-unthemed .fc-button:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.fc-unthemed .show > .dropdown-toggle.fc-button {
  color: $white;
  background-color: #3c53e1;
  border-color: #3149e0;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.fc-unthemed .fc-button:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.fc-unthemed .fc-button:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.fc-unthemed .show > .dropdown-toggle.fc-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 142, 235, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-secondary:hover {
  color: $white;
  background-color: #7c96bc;
  border-color: #738fb8;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 150, 177, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #212529;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: #738fb8;
  border-color: #6b88b3;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 150, 177, 0.5);
}

.btn-success {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-success:hover {
  color: $white;
  background-color: #00a67c;
  border-color: #009973;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 212, 168, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: $white;
  background-color: #009973;
  border-color: #008c69;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 212, 168, 0.5);
}

.btn-info {
  color: $white;
  background-color: $info;
  border-color: $info;
}
.btn-info:hover {
  color: $white;
  background-color: #01a4f0;
  border-color: #019be3;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 192, 254, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: $white;
  background-color: $info;
  border-color: $info;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: $white;
  background-color: #019be3;
  border-color: #0192d6;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 192, 254, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: $warning;
  border-color: $warning;
}
.btn-warning:hover {
  color: #212529;
  background-color: #d2a005;
  border-color: #c59605;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 165, 11, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: $warning;
  border-color: $warning;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: $white;
  background-color: #c59605;
  border-color: #b98d04;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 165, 11, 0.5);
}

.btn-danger {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-danger:hover {
  color: $white;
  background-color: #ef3039;
  border-color: #ee252e;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 110, 116, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: $white;
  background-color: #ee252e;
  border-color: #ed1922;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 110, 116, 0.5);
}

.btn-light {
  color: #212529;
  background-color: $light;
  border-color: $light;
}
.btn-light:hover {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: $light;
  border-color: $light;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #cbd3da;
  border-color: #c4ccd4;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}

.btn-dark {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn-dark:hover {
  color: $white;
  background-color: #1b2035;
  border-color: #171b2c;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 79, 105, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: #171b2c;
  border-color: #121624;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 79, 105, 0.5);
}

.btn-tertiary {
  color: #212529;
  background-color: $tertiary;
  border-color: $tertiary;
}
.btn-tertiary:hover {
  color: $white;
  background-color: #7384ea;
  border-color: $primary;
}
.btn-tertiary:focus,
.btn-tertiary.focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 142, 209, 0.5);
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: #212529;
  background-color: $tertiary;
  border-color: $tertiary;
}
.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: #5d70e6;
}
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 142, 209, 0.5);
}

.btn-outline-primary {
  color: $primary;
  border-color: $primary;
}
.btn-outline-primary:hover {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 122, 232, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $primary;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 122, 232, 0.5);
}

.btn-outline-secondary {
  color: $secondary;
  border-color: $secondary;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 170, 201, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: $secondary;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 170, 201, 0.5);
}

.btn-outline-success {
  color: $success;
  border-color: $success;
}
.btn-outline-success:hover {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 204, 153, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: $success;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 204, 153, 0.5);
}

.btn-outline-info {
  color: $info;
  border-color: $info;
}
.btn-outline-info:hover {
  color: $white;
  background-color: $info;
  border-color: $info;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 181, 254, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: $info;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: $white;
  background-color: $info;
  border-color: $info;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 181, 254, 0.5);
}

.btn-outline-warning {
  color: $warning;
  border-color: $warning;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: $warning;
  border-color: $warning;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 188, 6, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: $warning;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: $warning;
  border-color: $warning;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 188, 6, 0.5);
}

.btn-outline-danger {
  color: $danger;
  border-color: $danger;
}
.btn-outline-danger:hover {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 84, 91, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: $danger;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 84, 91, 0.5);
}

.btn-outline-light {
  color: $light;
  border-color: $light;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: $light;
  border-color: $light;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: $light;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: $light;
  border-color: $light;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-dark {
  color: $dark;
  border-color: $dark;
}
.btn-outline-dark:hover {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 48, 78, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: $dark;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 48, 78, 0.5);
}

.btn-outline-tertiary {
  color: $tertiary;
  border-color: $tertiary;
}
.btn-outline-tertiary:hover {
  color: #212529;
  background-color: $tertiary;
  border-color: $tertiary;
}
.btn-outline-tertiary:focus,
.btn-outline-tertiary.focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 161, 239, 0.5);
}
.btn-outline-tertiary.disabled,
.btn-outline-tertiary:disabled {
  color: $tertiary;
  background-color: transparent;
}
.btn-outline-tertiary:not(:disabled):not(.disabled):active,
.btn-outline-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-outline-tertiary.dropdown-toggle {
  color: #212529;
  background-color: $tertiary;
  border-color: $tertiary;
}
.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus,
.btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 161, 239, 0.5);
}

.btn-link {
  font-weight: 400;
  color: $primary;
  text-decoration: none;
}
.btn-link:hover {
  color: #2640de;
  text-decoration: underline;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: $gray;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn,
.fc-unthemed .btn-group-lg > .fc-button {
  padding: 0.35rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn,
.fc-unthemed .btn-group-sm > .fc-button {
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.1rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #3e4676;
  text-align: left;
  list-style: none;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid $light;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #495057;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: $white;
  text-decoration: none;
  background-color: $primary;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: $gray;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: $gray;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.35rem 1.5rem;
  color: #495057;
}

.btn-group,
.fc-unthemed .fc-button-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.fc-unthemed .fc-button-group > .btn,
.fc-unthemed .btn-group > .fc-button,
.fc-unthemed .fc-button-group > .fc-button,
.btn-group-vertical > .btn,
.fc-unthemed .btn-group-vertical > .fc-button {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.fc-unthemed .fc-button-group > .btn:hover,
.fc-unthemed .btn-group > .fc-button:hover,
.fc-unthemed .fc-button-group > .fc-button:hover,
.btn-group-vertical > .btn:hover,
.fc-unthemed .btn-group-vertical > .fc-button:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.fc-unthemed .fc-button-group > .btn:focus,
.fc-unthemed .btn-group > .fc-button:focus,
.fc-unthemed .fc-button-group > .fc-button:focus,
.btn-group > .btn:active,
.fc-unthemed .fc-button-group > .btn:active,
.fc-unthemed .btn-group > .fc-button:active,
.fc-unthemed .fc-button-group > .fc-button:active,
.btn-group > .btn.active,
.fc-unthemed .fc-button-group > .btn.active,
.fc-unthemed .btn-group > .active.fc-button,
.fc-unthemed .fc-button-group > .active.fc-button,
.btn-group-vertical > .btn:focus,
.fc-unthemed .btn-group-vertical > .fc-button:focus,
.btn-group-vertical > .btn:active,
.fc-unthemed .btn-group-vertical > .fc-button:active,
.btn-group-vertical > .btn.active,
.fc-unthemed .btn-group-vertical > .active.fc-button {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.fc-unthemed .fc-button-group > .btn:not(:first-child),
.fc-unthemed .btn-group > .fc-button:not(:first-child),
.fc-unthemed .fc-button-group > .fc-button:not(:first-child),
.btn-group > .btn-group:not(:first-child),
.fc-unthemed .fc-button-group > .btn-group:not(:first-child),
.fc-unthemed .btn-group > .fc-button-group:not(:first-child),
.fc-unthemed .fc-button-group > .fc-button-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.fc-unthemed .fc-button-group > .btn:not(:last-child):not(.dropdown-toggle),
.fc-unthemed .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc-unthemed .fc-button-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.fc-unthemed .fc-button-group > .btn-group:not(:last-child) > .btn,
.fc-unthemed .btn-group > .fc-button-group:not(:last-child) > .btn,
.fc-unthemed .fc-button-group > .fc-button-group:not(:last-child) > .btn,
.fc-unthemed .btn-group > .btn-group:not(:last-child) > .fc-button,
.fc-unthemed .fc-button-group > .btn-group:not(:last-child) > .fc-button,
.fc-unthemed .btn-group > .fc-button-group:not(:last-child) > .fc-button,
.fc-unthemed .fc-button-group > .fc-button-group:not(:last-child) > .fc-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.fc-unthemed .fc-button-group > .btn:not(:first-child),
.fc-unthemed .btn-group > .fc-button:not(:first-child),
.fc-unthemed .fc-button-group > .fc-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.fc-unthemed .fc-button-group > .btn-group:not(:first-child) > .btn,
.fc-unthemed .btn-group > .fc-button-group:not(:first-child) > .btn,
.fc-unthemed .fc-button-group > .fc-button-group:not(:first-child) > .btn,
.fc-unthemed .btn-group > .btn-group:not(:first-child) > .fc-button,
.fc-unthemed .fc-button-group > .btn-group:not(:first-child) > .fc-button,
.fc-unthemed .btn-group > .fc-button-group:not(:first-child) > .fc-button,
.fc-unthemed .fc-button-group > .fc-button-group:not(:first-child) > .fc-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.525rem;
  padding-left: 0.525rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.fc-unthemed .btn-group-sm > .fc-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.fc-unthemed .btn-group-lg > .fc-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.fc-unthemed .btn-group-vertical > .fc-button,
.btn-group-vertical > .btn-group,
.fc-unthemed .btn-group-vertical > .fc-button-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.fc-unthemed .btn-group-vertical > .fc-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child),
.fc-unthemed .btn-group-vertical > .fc-button-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.fc-unthemed .btn-group-vertical > .fc-button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.fc-unthemed .btn-group-vertical > .fc-button-group:not(:last-child) > .btn,
.fc-unthemed .btn-group-vertical > .btn-group:not(:last-child) > .fc-button,
.fc-unthemed .btn-group-vertical > .fc-button-group:not(:last-child) > .fc-button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.fc-unthemed .btn-group-vertical > .fc-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.fc-unthemed .btn-group-vertical > .fc-button-group:not(:first-child) > .btn,
.fc-unthemed .btn-group-vertical > .btn-group:not(:first-child) > .fc-button,
.fc-unthemed .btn-group-vertical > .fc-button-group:not(:first-child) > .fc-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.fc-unthemed .btn-group-toggle > .fc-button,
.btn-group-toggle > .btn-group > .btn,
.fc-unthemed .btn-group-toggle > .fc-button-group > .btn,
.fc-unthemed .btn-group-toggle > .btn-group > .fc-button,
.fc-unthemed .btn-group-toggle > .fc-button-group > .fc-button {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.fc-unthemed .btn-group-toggle > .fc-button input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.fc-unthemed .btn-group-toggle > .fc-button input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.fc-unthemed .btn-group-toggle > .fc-button-group > .btn input[type="radio"],
.fc-unthemed .btn-group-toggle > .btn-group > .fc-button input[type="radio"],
.fc-unthemed .btn-group-toggle > .fc-button-group > .fc-button input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.fc-unthemed .btn-group-toggle > .fc-button-group > .btn input[type="checkbox"],
.fc-unthemed .btn-group-toggle > .btn-group > .fc-button input[type="checkbox"],
.fc-unthemed .btn-group-toggle > .fc-button-group > .fc-button input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.actionButtons {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  z-index: 5;
}

.actionButtonItem:focus {
  opacity: 0.85;
}
