.payment-calculations {
  border-left: 1px solid #e3e3e3;
  padding: 40px;
}

.payment-section-title {
  padding: 30px 0px 10px 0px;
  font-weight: 300;
  font-size: 1.2rem;
}

.bt1 {
  border-top: 1px solid #e3e3e3;
}

.taxes-disclaimer {
  padding-left: 10px;
  opacity: .5;
  font-size: 0.9rem;
  margin-top: 20px;
}

.payment-calculator-btn:first-child {
  border-radius: 5px 0 0 5px;
}

.payment-calculator-btn:last-child {
  border-radius: 0 5px 5px 0;
}

.payment-calculator-btn {
  color: $dark;
  display: inline-block;
  width: 110px;
  height: 52px;
  background-color: #ffffff;
  border: 1px solid #fafafa;
  padding: 7px 4px 0px 4px;
}

.payment-calculator-btn:hover, .payment-calculator-btn-active:hover {
  cursor: pointer;
}

.payment-calculator-btn-active {
  color: #ffffff;
  display: inline-block;
  width: 110px;
  height: 52px;
  background-color: $dark;
  padding: 7px 4px 0px 4px;
}

.payment-calculator-btn-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: $dark;
  margin-bottom: 0px;
}

.payment-calculator-btn-subtext {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #979797;
  line-height: 1.3;
}

.payment-calculator-line {
  display: flex;
}

.payment-calculator-hint {
  flex: 1 0 0%;
}

.estimated-apr {
  padding-top: 10px;
  margin-bottom: 0px;
  font-size: 0.85rem;
  font-weight: 300;
  opacity: 0.5;
}

.estimated-monthly span {
  font-weight: 300;
  font-size: 2rem;
}

.payment-calculator-btn-active > .payment-calculator-btn-title {
  color: #fff;
}

.payment-calculator-btn-active > .payment-calculator-btn-subtext {
  color: #fff;
}