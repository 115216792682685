.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3e4676;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}

.table-hover tbody tr:hover {
  color: #3e4676;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d5daf9;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b0baf3;
}

.table-hover .table-primary:hover {
  background-color: #bfc6f6;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bfc6f6;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e1e7f0;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c8d3e3;
}

.table-hover .table-secondary:hover {
  background-color: #d0d9e8;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d0d9e8;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f1e2;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae4ca;
}

.table-hover .table-success:hover {
  background-color: #a3edd9;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a3edd9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bfeaff;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #87d9fe;
}

.table-hover .table-info:hover {
  background-color: #a6e2ff;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a6e2ff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fdecb9;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fbdc7e;
}

.table-hover .table-warning:hover {
  background-color: #fce5a0;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fce5a0;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbcfd1;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f8a6aa;
}

.table-hover .table-danger:hover {
  background-color: #f9b7ba;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9b7ba;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9fafb;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f5f7;
}

.table-hover .table-light:hover {
  background-color: #eaedf1;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eaedf1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c3c5cd;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8f93a3;
}

.table-hover .table-dark:hover {
  background-color: #b5b8c1;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b5b8c1;
}

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #e1e5fa;
}

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #c7cef7;
}

.table-hover .table-tertiary:hover {
  background-color: #cbd2f6;
}
.table-hover .table-tertiary:hover > td,
.table-hover .table-tertiary:hover > th {
  background-color: #cbd2f6;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: $white;
  background-color: $gray-dark;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: $light;
  border-color: #dee2e6;
}

.table-dark {
  color: $white;
  background-color: $gray-dark;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: $white;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
