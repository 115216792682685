@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Hind+Vadodara:400,500&display=swap");
@import url("https://indestructibletype.com/fonts/Jost.css");

@import './variables.scss';
@import './main.scss';
@import './tables.scss';
@import './forms.scss';
@import './buttons.scss';
@import './inputs.scss';
@import './components.scss';
@import './helpers.scss';
@import './icons.scss';
@import './paymentCalculator.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";