.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #687ae8 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3c53e1 !important;
}

.bg-secondary {
  background-color: #95aac9 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #738fb8 !important;
}

.bg-success {
  background-color: #0c9 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #009973 !important;
}

.bg-info {
  background-color: #19b5fe !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #019be3 !important;
}

.bg-warning {
  background-color: #f7bc06 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c59605 !important;
}

.bg-danger {
  background-color: #f2545b !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ee252e !important;
}

.bg-light {
  background-color: #e9ecef !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cbd3da !important;
}

.bg-dark {
  background-color: #28304e !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #171b2c !important;
}

.bg-tertiary {
  background-color: #94a1ef !important;
}

a.bg-tertiary:hover,
a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #687ae8 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #687ae8 !important;
}

.border-secondary {
  border-color: #95aac9 !important;
}

.border-success {
  border-color: #0c9 !important;
}

.border-info {
  border-color: #19b5fe !important;
}

.border-warning {
  border-color: #f7bc06 !important;
}

.border-danger {
  border-color: #f2545b !important;
}

.border-light {
  border-color: #e9ecef !important;
}

.border-dark {
  border-color: #28304e !important;
}

.border-tertiary {
  border-color: #94a1ef !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.1rem !important;
}

.rounded {
  border-radius: 0.2rem !important;
}

.rounded-top {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}

.rounded-right {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-left {
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1440px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow {
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow-lg {
  box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -6rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ml-lg-2-5,
  .mx-lg-2-5 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6rem !important;
  }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 6rem !important;
  }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 6rem !important;
  }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 6rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 6rem !important;
  }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 6rem !important;
  }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 6rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6rem !important;
  }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -6rem !important;
  }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -6rem !important;
  }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -6rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #687ae8 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #2640de !important;
}

.text-secondary {
  color: #95aac9 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #6281af !important;
}

.text-success {
  color: #0c9 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #008060 !important;
}

.text-info {
  color: #19b5fe !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #018aca !important;
}

.text-warning {
  color: #f7bc06 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ac8304 !important;
}

.text-danger {
  color: #f2545b !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e8121b !important;
}

.text-light {
  color: #e9ecef !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #bdc6cf !important;
}

.text-dark {
  color: #28304e !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #0e111b !important;
}

.text-tertiary {
  color: #94a1ef !important;
}

a.text-tertiary:hover,
a.text-tertiary:focus {
  color: #5267e5 !important;
}

.text-body {
  color: #3e4676 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.accordion .card:not(:last-child) {
  margin-bottom: 0.5rem;
}

.accordion .card-header {
  border-bottom: 0;
}

.accordion .card-body {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion .card-title a {
  color: #3e4676;
}

.alert {
  padding: 0;
  display: -webkit-box;
  display: flex;
  color: $white;
}
.alert .close:hover,
.alert .close:focus {
  color: $white;
  opacity: 1;
}

.alert-outline,
.alert-outline-coloured {
  color: #3e4676;
  background: white;
}
.alert-outline hr,
.alert-outline-coloured hr {
  border-top-color: #ced4da;
}
.alert-outline .close:hover,
.alert-outline .close:focus,
.alert-outline-coloured .close:hover,
.alert-outline-coloured .close:focus {
  color: $gray-dark;
}
.alert-outline .alert-message,
.alert-outline-coloured .alert-message {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  border: 1px solid #ced4da;
}
.alert-outline .alert-message:not(:nth-child(2)),
.alert-outline-coloured .alert-message:not(:nth-child(2)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.alert-outline .alert-icon,
.alert-outline-coloured .alert-icon {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  color: $white;
}
.alert-outline.alert-primary .alert-icon,
.alert-outline-coloured.alert-primary .alert-icon {
  background-color: $primary;
}
.alert-outline.alert-secondary .alert-icon,
.alert-outline-coloured.alert-secondary .alert-icon {
  background-color: $secondary;
}
.alert-outline.alert-success .alert-icon,
.alert-outline-coloured.alert-success .alert-icon {
  background-color: $success;
}
.alert-outline.alert-info .alert-icon,
.alert-outline-coloured.alert-info .alert-icon {
  background-color: $info;
}
.alert-outline.alert-warning .alert-icon,
.alert-outline-coloured.alert-warning .alert-icon {
  background-color: $warning;
}
.alert-outline.alert-danger .alert-icon,
.alert-outline-coloured.alert-danger .alert-icon {
  background-color: $danger;
}
.alert-outline.alert-light .alert-icon,
.alert-outline-coloured.alert-light .alert-icon {
  background-color: $light;
}
.alert-outline.alert-dark .alert-icon,
.alert-outline-coloured.alert-dark .alert-icon {
  background-color: $dark;
}
.alert-outline.alert-tertiary .alert-icon,
.alert-outline-coloured.alert-tertiary .alert-icon {
  background-color: $tertiary;
}

.alert-outline-coloured.alert-primary .alert-message {
  border-color: $primary;
}

.alert-outline-coloured.alert-secondary .alert-message {
  border-color: $secondary;
}

.alert-outline-coloured.alert-success .alert-message {
  border-color: $success;
}

.alert-outline-coloured.alert-info .alert-message {
  border-color: $info;
}

.alert-outline-coloured.alert-warning .alert-message {
  border-color: $warning;
}

.alert-outline-coloured.alert-danger .alert-message {
  border-color: $danger;
}

.alert-outline-coloured.alert-light .alert-message {
  border-color: $light;
}

.alert-outline-coloured.alert-dark .alert-message {
  border-color: $dark;
}

.alert-outline-coloured.alert-tertiary .alert-message {
  border-color: $tertiary;
}

.alert-icon {
  padding: 0.95rem 0.95rem;
  background: rgba(255, 255, 255, 0.1);
}

.alert-message {
  padding: 0.95rem 0.95rem;
  width: 100%;
  box-sizing: border-box;
}

.avatar {
  width: 40px;
  height: 40px;
}

.avatar-title {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: $white;
}

.badge {
  color: $white;
}

.breadcrumb-item a {
  color: #dee2e6;
  font-weight: 400;
}

.breadcrumb-item.active {
  color: $white;
  font-weight: 500;
}

.btn-pill {
  border-radius: 10rem;
}

.btn-square {
  border-radius: 0;
}

.btn .feather,
.fc-unthemed .fc-button .feather {
  width: 14px;
  height: 14px;
}

.btn-primary,
.fc-unthemed .fc-button,
.btn-primary:focus,
.fc-unthemed .fc-button:focus,
.btn-primary.focus,
.fc-unthemed .focus.fc-button,
.btn-primary.disabled,
.fc-unthemed .disabled.fc-button,
.btn-primary:disabled,
.fc-unthemed .fc-button:disabled,
.show > .btn-primary.dropdown-toggle,
.fc-unthemed .show > .dropdown-toggle.fc-button {
  color: $white;
}

.btn-primary:hover:not(:disabled):not(.disabled),
.fc-unthemed .fc-button:hover:not(:disabled):not(.disabled),
.btn-primary.hover:not(:disabled):not(.disabled),
.fc-unthemed .hover.fc-button:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-primary:hover:not(:disabled):not(.disabled),
.btn-outline-primary.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.disabled,
.btn-secondary:disabled,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
}

.btn-secondary:hover:not(:disabled):not(.disabled),
.btn-secondary.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-secondary:hover:not(:disabled):not(.disabled),
.btn-outline-secondary.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-success,
.btn-success:focus,
.btn-success.focus,
.btn-success.disabled,
.btn-success:disabled,
.show > .btn-success.dropdown-toggle {
  color: $white;
}

.btn-success:hover:not(:disabled):not(.disabled),
.btn-success.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-success:hover:not(:disabled):not(.disabled),
.btn-outline-success.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-info,
.btn-info:focus,
.btn-info.focus,
.btn-info.disabled,
.btn-info:disabled,
.show > .btn-info.dropdown-toggle {
  color: $white;
}

.btn-info:hover:not(:disabled):not(.disabled),
.btn-info.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-info:hover:not(:disabled):not(.disabled),
.btn-outline-info.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-warning,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.disabled,
.btn-warning:disabled,
.show > .btn-warning.dropdown-toggle {
  color: $white;
}

.btn-warning:hover:not(:disabled):not(.disabled),
.btn-warning.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-warning:hover:not(:disabled):not(.disabled),
.btn-outline-warning.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-danger,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.disabled,
.btn-danger:disabled,
.show > .btn-danger.dropdown-toggle {
  color: $white;
}

.btn-danger:hover:not(:disabled):not(.disabled),
.btn-danger.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-danger:hover:not(:disabled):not(.disabled),
.btn-outline-danger.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-light,
.btn-light:focus,
.btn-light.focus,
.btn-light.disabled,
.btn-light:disabled,
.show > .btn-light.dropdown-toggle {
  color: $white;
}

.btn-light:hover:not(:disabled):not(.disabled),
.btn-light.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-light:hover:not(:disabled):not(.disabled),
.btn-outline-light.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-dark,
.btn-dark:focus,
.btn-dark.focus,
.btn-dark.disabled,
.btn-dark:disabled,
.show > .btn-dark.dropdown-toggle {
  color: $white;
}

.btn-dark:hover:not(:disabled):not(.disabled),
.btn-dark.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-dark:hover:not(:disabled):not(.disabled),
.btn-outline-dark.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-tertiary,
.btn-tertiary:focus,
.btn-tertiary.focus,
.btn-tertiary.disabled,
.btn-tertiary:disabled,
.show > .btn-tertiary.dropdown-toggle {
  color: $white;
}

.btn-tertiary:hover:not(:disabled):not(.disabled),
.btn-tertiary.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-tertiary:hover:not(:disabled):not(.disabled),
.btn-outline-tertiary.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active,
.btn-outline-tertiary:not(:disabled):not(.disabled).active {
  color: $white;
}

.btn-facebook {
  color: $white;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:hover {
  color: $white;
  background-color: #30497c;
  border-color: #2d4373;
}
.btn-facebook:focus,
.btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-facebook.disabled,
.btn-facebook:disabled {
  color: $white;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: $white;
  background-color: #2d4373;
  border-color: #293e6a;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-facebook,
.btn-facebook:focus,
.btn-facebook.focus,
.btn-facebook.disabled,
.btn-facebook:disabled,
.show > .btn-facebook.dropdown-toggle {
  color: $white;
}
.btn-facebook:hover:not(:disabled):not(.disabled),
.btn-facebook.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-whatsapp {
  color: $white;
  background-color: #06e676;
  border-color: #06e676;
}
.btn-whatsapp:hover {
  color: $white;
  background-color: #05b95f;
  border-color: #05b95f;
}
.btn-whatsapp:focus,
.btn-whatsapp.focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 167, 88, 0.5);
}
.btn-whatsapp.disabled,
.btn-whatsapp:disabled {
  color: $white;
  background-color: #06e676;
  border-color: #06e676;
}
.btn-whatsapp:not(:disabled):not(.disabled):active,
.btn-whatsapp:not(:disabled):not(.disabled).active,
.show > .btn-whatsapp.dropdown-toggle {
  color: $white;
  background-color: #05b95f;
  border-color: #05b95f;
}
.btn-whatsapp:not(:disabled):not(.disabled):active:focus,
.btn-whatsapp:not(:disabled):not(.disabled).active:focus,
.show > .btn-whatsapp.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}
.btn-whatsapp,
.btn-whatsapp:focus,
.btn-whatsapp.focus,
.btn-whatsapp.disabled,
.btn-whatsapp:disabled,
.show > .btn-facebook.dropdown-toggle {
  color: $white;
}
.btn-whatsapp:hover:not(:disabled):not(.disabled),
.btn-whatsapp.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-twitter {
  color: $white;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:hover {
  color: $white;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}
.btn-twitter:focus,
.btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}
.btn-twitter.disabled,
.btn-twitter:disabled {
  color: $white;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: $white;
  background-color: #0c85d0;
  border-color: #0b7ec4;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}
.btn-twitter,
.btn-twitter:focus,
.btn-twitter.focus,
.btn-twitter.disabled,
.btn-twitter:disabled,
.show > .btn-twitter.dropdown-toggle {
  color: $white;
}
.btn-twitter:hover:not(:disabled):not(.disabled),
.btn-twitter.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-google {
  color: $white;
  background-color: #dc4e41;
  border-color: #dc4e41;
}
.btn-google:hover {
  color: $white;
  background-color: #d03526;
  border-color: #c63224;
}
.btn-google:focus,
.btn-google.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);
}
.btn-google.disabled,
.btn-google:disabled {
  color: $white;
  background-color: #dc4e41;
  border-color: #dc4e41;
}
.btn-google:not(:disabled):not(.disabled):active,
.btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: $white;
  background-color: #c63224;
  border-color: #bb2f22;
}
.btn-google:not(:disabled):not(.disabled):active:focus,
.btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);
}
.btn-google,
.btn-google:focus,
.btn-google.focus,
.btn-google.disabled,
.btn-google:disabled,
.show > .btn-google.dropdown-toggle {
  color: $white;
}
.btn-google:hover:not(:disabled):not(.disabled),
.btn-google.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-youtube {
  color: $white;
  background-color: #f00;
  border-color: #f00;
}
.btn-youtube:hover {
  color: $white;
  background-color: #d90000;
  border-color: #cc0000;
}
.btn-youtube:focus,
.btn-youtube.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.btn-youtube.disabled,
.btn-youtube:disabled {
  color: $white;
  background-color: #f00;
  border-color: #f00;
}
.btn-youtube:not(:disabled):not(.disabled):active,
.btn-youtube:not(:disabled):not(.disabled).active,
.show > .btn-youtube.dropdown-toggle {
  color: $white;
  background-color: #cc0000;
  border-color: #bf0000;
}
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.btn-youtube:not(:disabled):not(.disabled).active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.btn-youtube,
.btn-youtube:focus,
.btn-youtube.focus,
.btn-youtube.disabled,
.btn-youtube:disabled,
.show > .btn-youtube.dropdown-toggle {
  color: $white;
}
.btn-youtube:hover:not(:disabled):not(.disabled),
.btn-youtube.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-vimeo {
  color: $white;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}
.btn-vimeo:hover {
  color: $white;
  background-color: #139ecb;
  border-color: #1295bf;
}
.btn-vimeo:focus,
.btn-vimeo.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 194, 237, 0.5);
}
.btn-vimeo.disabled,
.btn-vimeo:disabled {
  color: $white;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}
.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle {
  color: $white;
  background-color: #1295bf;
  border-color: #108cb4;
}
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 194, 237, 0.5);
}
.btn-vimeo,
.btn-vimeo:focus,
.btn-vimeo.focus,
.btn-vimeo.disabled,
.btn-vimeo:disabled,
.show > .btn-vimeo.dropdown-toggle {
  color: $white;
}
.btn-vimeo:hover:not(:disabled):not(.disabled),
.btn-vimeo.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-dribbble {
  color: $white;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:hover {
  color: $white;
  background-color: #e62a72;
  border-color: #e51e6b;
}
.btn-dribbble:focus,
.btn-dribbble.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}
.btn-dribbble.disabled,
.btn-dribbble:disabled {
  color: $white;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active,
.btn-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-dribbble.dropdown-toggle {
  color: $white;
  background-color: #e51e6b;
  border-color: #dc1a65;
}
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}
.btn-dribbble,
.btn-dribbble:focus,
.btn-dribbble.focus,
.btn-dribbble.disabled,
.btn-dribbble:disabled,
.show > .btn-dribbble.dropdown-toggle {
  color: $white;
}
.btn-dribbble:hover:not(:disabled):not(.disabled),
.btn-dribbble.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-github {
  color: $white;
  background-color: #181717;
  border-color: #181717;
}
.btn-github:hover {
  color: $white;
  background-color: #040404;
  border-color: black;
}
.btn-github:focus,
.btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5);
}
.btn-github.disabled,
.btn-github:disabled {
  color: $white;
  background-color: #181717;
  border-color: #181717;
}
.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: $white;
  background-color: black;
  border-color: black;
}
.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5);
}
.btn-github,
.btn-github:focus,
.btn-github.focus,
.btn-github.disabled,
.btn-github:disabled,
.show > .btn-github.dropdown-toggle {
  color: $white;
}
.btn-github:hover:not(:disabled):not(.disabled),
.btn-github.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-instagram {
  color: $white;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:hover {
  color: $white;
  background-color: #de1f44;
  border-color: #d31e40;
}
.btn-instagram:focus,
.btn-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}
.btn-instagram.disabled,
.btn-instagram:disabled {
  color: $white;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: $white;
  background-color: #d31e40;
  border-color: #c81c3d;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}
.btn-instagram,
.btn-instagram:focus,
.btn-instagram.focus,
.btn-instagram.disabled,
.btn-instagram:disabled,
.show > .btn-instagram.dropdown-toggle {
  color: $white;
}
.btn-instagram:hover:not(:disabled):not(.disabled),
.btn-instagram.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-pinterest {
  color: $white;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:hover {
  color: $white;
  background-color: #980617;
  border-color: #8c0615;
}
.btn-pinterest:focus,
.btn-pinterest.focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}
.btn-pinterest.disabled,
.btn-pinterest:disabled {
  color: $white;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle {
  color: $white;
  background-color: #8c0615;
  border-color: #800513;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}
.btn-pinterest,
.btn-pinterest:focus,
.btn-pinterest.focus,
.btn-pinterest.disabled,
.btn-pinterest:disabled,
.show > .btn-pinterest.dropdown-toggle {
  color: $white;
}
.btn-pinterest:hover:not(:disabled):not(.disabled),
.btn-pinterest.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-flickr {
  color: $white;
  background-color: #0063dc;
  border-color: #0063dc;
}
.btn-flickr:hover {
  color: $white;
  background-color: #0052b6;
  border-color: #004ca9;
}
.btn-flickr:focus,
.btn-flickr.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5);
}
.btn-flickr.disabled,
.btn-flickr:disabled {
  color: $white;
  background-color: #0063dc;
  border-color: #0063dc;
}
.btn-flickr:not(:disabled):not(.disabled):active,
.btn-flickr:not(:disabled):not(.disabled).active,
.show > .btn-flickr.dropdown-toggle {
  color: $white;
  background-color: #004ca9;
  border-color: #00469c;
}
.btn-flickr:not(:disabled):not(.disabled):active:focus,
.btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5);
}
.btn-flickr,
.btn-flickr:focus,
.btn-flickr.focus,
.btn-flickr.disabled,
.btn-flickr:disabled,
.show > .btn-flickr.dropdown-toggle {
  color: $white;
}
.btn-flickr:hover:not(:disabled):not(.disabled),
.btn-flickr.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-bitbucket {
  color: $white;
  background-color: #0052cc;
  border-color: #0052cc;
}
.btn-bitbucket:hover {
  color: $white;
  background-color: #0043a6;
  border-color: #003e99;
}
.btn-bitbucket:focus,
.btn-bitbucket.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5);
}
.btn-bitbucket.disabled,
.btn-bitbucket:disabled {
  color: $white;
  background-color: #0052cc;
  border-color: #0052cc;
}
.btn-bitbucket:not(:disabled):not(.disabled):active,
.btn-bitbucket:not(:disabled):not(.disabled).active,
.show > .btn-bitbucket.dropdown-toggle {
  color: $white;
  background-color: #003e99;
  border-color: #00388c;
}
.btn-bitbucket:not(:disabled):not(.disabled):active:focus,
.btn-bitbucket:not(:disabled):not(.disabled).active:focus,
.show > .btn-bitbucket.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5);
}
.btn-bitbucket,
.btn-bitbucket:focus,
.btn-bitbucket.focus,
.btn-bitbucket.disabled,
.btn-bitbucket:disabled,
.show > .btn-bitbucket.dropdown-toggle {
  color: $white;
}
.btn-bitbucket:hover:not(:disabled):not(.disabled),
.btn-bitbucket.hover:not(:disabled):not(.disabled) {
  color: $white;
}

.btn-light,
.btn-light:focus,
.btn-light.focus,
.btn-light.disabled,
.btn-light:disabled,
.show > .btn-light.dropdown-toggle,
.btn-white,
.btn-white:focus,
.btn-white.focus,
.btn-white.disabled,
.btn-white:disabled,
.show > .btn-white.dropdown-toggle {
  color: $gray-dark;
}

.btn-light:hover:not(:disabled):not(.disabled),
.btn-light.hover:not(:disabled):not(.disabled),
.btn-white:hover:not(:disabled):not(.disabled),
.btn-white.hover:not(:disabled):not(.disabled) {
  color: $gray-dark;
}

.btn-outline-light:hover:not(:disabled):not(.disabled),
.btn-outline-light.hover:not(:disabled):not(.disabled),
.btn-outline-white:hover:not(:disabled):not(.disabled),
.btn-outline-white.hover:not(:disabled):not(.disabled) {
  color: $gray-dark;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active {
  color: $gray-dark;
}

.card {
  box-shadow: 0 2px 8px rgba(126, 142, 177, 0.30);
  margin-bottom: 1.5rem;
}

a.card {
  cursor: pointer;
  text-decoration: none;
}

.card-header {
  background: transparent;
}

.card-body {
  padding-top: 0.75rem;
}

.card-title {
  color: #3e4676;
  font-size: 0.9375rem;
  line-height: 1.5;
}

.card-subtitle {
  font-weight: 400;
}

.card-table {
  margin-bottom: 0;
}

.card-table tr td:first-child,
.card-table tr th:first-child {
  padding-left: 1.25rem;
}

.card-table tr td:last-child,
.card-table tr th:last-child {
  padding-right: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  max-width: 90%;
  margin: 5%;
  height: auto;
}
@media all and (-ms-high-contrast: none) {
  .card-img,
  .card-img-top,
  .card-img-bottom {
    height: 100%;
  }
}

.card-actions a {
  color: #3e4676;
  text-decoration: none;
}

.card-actions svg {
  width: 16px;
  height: 16px;
}

.card-actions .dropdown {
  line-height: 1.4;
}

.card-img-hover {
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.card-img-hover:hover {
  -webkit-transform: scale(1.035);
  transform: scale(1.035);
}

.chart {
  margin: auto;
  position: relative;
  width: 100%;
  min-height: 300px;
}
.chart-xs {
  min-height: 200px;
}
.chart-sm {
  min-height: 250px;
}
.chart-lg {
  min-height: 350px;
}
.chart canvas {
  max-width: 100%;
}

@media (min-width: 768px) {
  .collapse.collapse-disabled-md {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

.content {
  margin-top: 3rem;
  padding: 0 0.5rem;
}
@media (min-width: 992px) {
  .content {
    padding: 0 1.5rem;
  }
}

.navbar-nav .dropdown-menu {
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.05);
}

.dropdown a {
  cursor: pointer;
}

.dropdown .dropdown-menu.show {
  -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.dropdown-toggle:after {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dropdown-item {
  -webkit-transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}

.dropdown-menu-lg {
  min-width: 20rem;
}

.dropdown .list-group .list-group-item {
  border-width: 0;
  border-bottom-width: 1px;
  margin-bottom: 0;
}
.dropdown .list-group .list-group-item:first-child,
.dropdown .list-group .list-group-item:last-child {
  border-radius: 0;
}
.dropdown .list-group .list-group-item:hover {
  background: #f8f9fa;
}

.dropdown-menu-header {
  padding: 0.75rem;
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid #dee2e6;
}

.dropdown-menu-footer {
  padding: 0.5rem;
  text-align: center;
  display: block;
  font-size: 0.75rem;
}

.dropdown-menu-left {
  right: auto !important;
  left: 0 !important;
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.feather {
  width: 16px;
  height: 16px;
}

.footer {
  padding: 1rem 0.5rem;
}
@media (min-width: 992px) {
  .footer {
    padding: 1rem 1.5rem;
  }
}
.footer ul {
  margin-bottom: 0;
}

.form-control-lite {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  color: $white;
}
.form-control-lite::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control-lite:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control-lite::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control-lite:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control-lite:focus {
  box-shadow: none;
  outline: 0;
  background: transparent;
  color: $white;
}

@media (min-width: 1440px) {
  .container-fluid {
    width: 95%;
  }
}

.hamburger,
.hamburger:before,
.hamburger:after {
  cursor: pointer;
  height: 2px;
  width: 20px;
  background: rgba(255, 255, 255, 0.75);
  display: block;
  content: "";
  -webkit-transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}

.hamburger {
  position: relative;
}

.hamburger:before {
  top: -6.5px;
  width: 20px;
  position: absolute;
}

.hamburger:after {
  bottom: -6.5px;
  width: 20px;
  position: absolute;
}

.sidebar-toggle:hover .hamburger,
.sidebar-toggle:hover .hamburger:before,
.sidebar-toggle:hover .hamburger:after {
  background: $white;
}

.hamburger-right,
.hamburger-right:before,
.hamburger-right:after {
  right: 0;
}

.header {
  position: relative;
  margin-bottom: 3rem;
}

.header-title {
  font-size: 3rem;
  color: $dark;
}

.header-subtitle {
  font-size: 1.4rem;
  color: $dark;
}

.icon {
  width: 60px;
  height: 60px;
  line-height: 52px;
  text-align: center;
  font-size: 2rem;
  margin: 0.5rem 0;
}
.icon svg {
  width: 32px;
  height: 32px;
}

.icon-primary {
  background-color: $primary;
}

.icon-secondary {
  background-color: $secondary;
}

.icon-success {
  background-color: $success;
}

.icon-info {
  background-color: $info;
}

.icon-warning {
  background-color: $warning;
}

.icon-danger {
  background-color: $danger;
}

.icon-light {
  background-color: $light;
}

.icon-dark {
  background-color: $dark;
}

.icon-tertiary {
  background-color: $tertiary;
}

.landing-features-icon {
  width: 42px;
  height: 42px;
  color: #5267e5;
}

.landing-bg {
  background: -webkit-gradient(linear, left top, right top, from(#171b2c), to(#394570));
  background: linear-gradient(90deg, #171b2c, #394570);
}

.landing-text {
  color: rgba(255, 255, 255, 0.75);
}

.landing-icon {
  -webkit-box-align: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 1rem;
  display: -webkit-box;
  display: flex;
  height: 56px;
  width: 56px;
}
.landing-icon svg {
  width: 28px;
  height: 28px;
  color: $primary;
}

.landing-stars {
  color: $yellow;
}

.landing-nav {
  box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.075);
}

.landing-img {
  box-shadow: 0 0 3rem rgba(28, 49, 181, 0.075);
  border: 1px solid rgba(0, 0, 0, 0.075);
}

.main {
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  -webkit-transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out,
    right 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
}
@media (max-width: 991.98px) {
  .main {
    overflow-y: hidden;
    width: 100vw;
    min-width: 100vw;
  }
}

.modal-primary .modal-content {
  background: $primary;
  color: $white;
}

.modal-primary h1,
.modal-primary h2,
.modal-primary h3,
.modal-primary h4,
.modal-primary h5,
.modal-primary h6,
.modal-primary .h1,
.modal-primary .h2,
.modal-primary .h3,
.modal-primary .h4,
.modal-primary .h5,
.modal-primary .h6 {
  color: $white;
}

.modal-secondary .modal-content {
  background: $secondary;
  color: #212529;
}

.modal-secondary h1,
.modal-secondary h2,
.modal-secondary h3,
.modal-secondary h4,
.modal-secondary h5,
.modal-secondary h6,
.modal-secondary .h1,
.modal-secondary .h2,
.modal-secondary .h3,
.modal-secondary .h4,
.modal-secondary .h5,
.modal-secondary .h6 {
  color: $white;
}

.modal-success .modal-content {
  background: $success;
  color: $white;
}

.modal-success h1,
.modal-success h2,
.modal-success h3,
.modal-success h4,
.modal-success h5,
.modal-success h6,
.modal-success .h1,
.modal-success .h2,
.modal-success .h3,
.modal-success .h4,
.modal-success .h5,
.modal-success .h6 {
  color: $white;
}

.modal-info .modal-content {
  background: $info;
  color: $white;
}

.modal-info h1,
.modal-info h2,
.modal-info h3,
.modal-info h4,
.modal-info h5,
.modal-info h6,
.modal-info .h1,
.modal-info .h2,
.modal-info .h3,
.modal-info .h4,
.modal-info .h5,
.modal-info .h6 {
  color: $white;
}

.modal-warning .modal-content {
  background: $warning;
  color: #212529;
}

.modal-warning h1,
.modal-warning h2,
.modal-warning h3,
.modal-warning h4,
.modal-warning h5,
.modal-warning h6,
.modal-warning .h1,
.modal-warning .h2,
.modal-warning .h3,
.modal-warning .h4,
.modal-warning .h5,
.modal-warning .h6 {
  color: $white;
}

.modal-danger .modal-content {
  background: $danger;
  color: $white;
}

.modal-danger h1,
.modal-danger h2,
.modal-danger h3,
.modal-danger h4,
.modal-danger h5,
.modal-danger h6,
.modal-danger .h1,
.modal-danger .h2,
.modal-danger .h3,
.modal-danger .h4,
.modal-danger .h5,
.modal-danger .h6 {
  color: $white;
}

.modal-light .modal-content {
  background: $light;
  color: #212529;
}

.modal-light h1,
.modal-light h2,
.modal-light h3,
.modal-light h4,
.modal-light h5,
.modal-light h6,
.modal-light .h1,
.modal-light .h2,
.modal-light .h3,
.modal-light .h4,
.modal-light .h5,
.modal-light .h6 {
  color: $white;
}

.modal-dark .modal-content {
  background: $dark;
  color: $white;
}

.modal-dark h1,
.modal-dark h2,
.modal-dark h3,
.modal-dark h4,
.modal-dark h5,
.modal-dark h6,
.modal-dark .h1,
.modal-dark .h2,
.modal-dark .h3,
.modal-dark .h4,
.modal-dark .h5,
.modal-dark .h6 {
  color: $white;
}

.modal-tertiary .modal-content {
  background: $tertiary;
  color: #212529;
}

.modal-tertiary h1,
.modal-tertiary h2,
.modal-tertiary h3,
.modal-tertiary h4,
.modal-tertiary h5,
.modal-tertiary h6,
.modal-tertiary .h1,
.modal-tertiary .h2,
.modal-tertiary .h3,
.modal-tertiary .h4,
.modal-tertiary .h5,
.modal-tertiary .h6 {
  color: $white;
}

.modal-colored .modal-header,
.modal-colored .modal-footer {
  border-color: rgba(255, 255, 255, 0.33);
}

.nav-link-sm {
  padding: 0.25rem 0.5rem;
}

.nav-link-lg {
  padding: 0.75rem 1.5rem;
}

.navbar .nav-item {
  line-height: 3rem;
}
.navbar .nav-item a {
  text-decoration: none;
}
.navbar .nav-item a:focus {
  outline: 0;
}
.navbar .nav-item .dropdown-menu {
  line-height: 1.5;
}
.navbar .nav-item .nav-link {
  font-weight: 500;
}
.navbar .nav-item .nav-link .svg-inline--fa,
.navbar .nav-item .nav-link .fas,
.navbar .nav-item .nav-link .far,
.navbar .nav-item .nav-link .fal,
.navbar .nav-item .nav-link .fab {
  font-size: 1.125rem;
}
.navbar .nav-item .nav-link:after {
  display: none;
}

@media (max-width: 575.98px) {
  .navbar {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: inherit;
  }
  .navbar-expand .navbar-nav .dropdown-menu-lg {
    min-width: 100%;
  }
}

.navbar-brand svg {
  width: 32px;
  height: 32px;
  fill: #7e8deb;
}

.navbar-theme .navbar-brand {
  color: $white;
}
.navbar-theme .navbar-brand:hover,
.navbar-theme .navbar-brand:focus {
  color: $white;
}

.navbar-theme .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-theme .navbar-nav .nav-link:hover,
.navbar-theme .navbar-nav .nav-link:focus {
  color: $white;
}
.navbar-theme .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-theme .navbar-nav .show > .nav-link,
.navbar-theme .navbar-nav .active > .nav-link,
.navbar-theme .navbar-nav .nav-link.show,
.navbar-theme .navbar-nav .nav-link.active {
  color: $white;
}

.navbar-theme .navbar-toggler {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-theme .navbar-toggler-icon {
  background-image: str-replace(
    url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
    "#",
    "%23"
  );
}

.navbar-theme .navbar-text {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-theme .navbar-text a {
  color: $white;
}
.navbar-theme .navbar-text a:hover,
.navbar-theme .navbar-text a:focus {
  color: $white;
}

.nav-item .indicator {
  background: $warning;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  height: 9px;
  width: 9px;
  position: absolute;
  top: 18px;
  right: 0;
  -webkit-transition: top 0.1s ease-out;
  transition: top 0.1s ease-out;
}

.nav-item:hover .indicator {
  top: 15px;
}

.nav-item a:focus {
  outline: 0;
}

body,
html,
#root {
  height: 100%;
}

body {
  overflow-y: scroll;
  opacity: 1 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow-x: hidden;
  }
}

.sidebar {
  min-width: 250px;
  max-width: 250px;
  -webkit-transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out,
    right 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  background: transparent;
  border-right: 1px solid #fafafa;
  z-index: 1;
}

.sidebar-brand,
.sidebar-brand:hover {
  padding: 1rem;
  background: $dark;
  font-size: 1.175rem;
  font-weight: 500;
  color: $white;
  width: 100%;
  display: block;
  text-decoration: none;
}
.sidebar-brand svg,
.sidebar-brand:hover svg {
  height: 32px;
  width: 32px;
  margin-right: 0.25rem;
  fill: #8997ed;
}

.sidebar-user {
  padding: 1.5rem 1rem 1rem;
  background: #fafafa;
  color: $dark;
  text-align: center;
}
.sidebar-user img {
  width: 64px;
  height: 64px;
}

.sidebar-content {
  background: $white;
  -webkit-transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out,
    right 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  height: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.sidebar-nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  flex: 1;
  background: #fafafa;
}

.sidebar-link,
a.sidebar-link {
  display: block;
  padding: 0.65rem 0.75rem;
  margin: 0 0.5rem;
  color: $dark;
  font-weight: 400;
  background: transparent;
  border-radius: 0.2rem;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}
.sidebar-link svg,
.sidebar-link .fas,
.sidebar-link .far,
.sidebar-link .fal,
.sidebar-link .fab,
a.sidebar-link svg,
a.sidebar-link .fas,
a.sidebar-link .far,
a.sidebar-link .fal,
a.sidebar-link .fab {
  margin-right: 0.75rem;
  color: $light;
}

.sidebar-link:focus {
  outline: 0;
}

.sidebar-link:hover {
  color: $dark;
  background: $light;
}
.sidebar-link:hover svg,
.sidebar-link:hover .fas,
.sidebar-link:hover .far,
.sidebar-link:hover .fal,
.sidebar-link:hover .fab {
  color: $dark;
}

.sidebar-item.active > .sidebar-link,
.sidebar-item.active .sidebar-link:hover {
  color: #212529;
  background: $light;
}
.sidebar-item.active > .sidebar-link svg,
.sidebar-item.active > .sidebar-link .fas,
.sidebar-item.active > .sidebar-link .far,
.sidebar-item.active > .sidebar-link .fal,
.sidebar-item.active > .sidebar-link .fab,
.sidebar-item.active .sidebar-link:hover svg,
.sidebar-item.active .sidebar-link:hover .fas,
.sidebar-item.active .sidebar-link:hover .far,
.sidebar-item.active .sidebar-link:hover .fal,
.sidebar-item.active .sidebar-link:hover .fab {
  color: $dark;
}

.sidebar-dropdown .sidebar-link {
  padding: 0.35rem 1.5rem 0.35rem 2.75rem;
  margin: 0 0.5rem;
  color: $dark;
  background: #fafafa;
  font-weight: 400;
  font-size: 90%;
}

.sidebar-dropdown .sidebar-item .sidebar-link:hover {
  color: $dark;
  background: $light;
  font-weight: 400;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link {
  color: #212529;
  background: $white;
  font-weight: 400;
}

.sidebar [data-toggle="collapse"] {
  position: relative;
}

.sidebar [data-toggle="collapse"]:before {
  content: " ";
  border: solid;
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 2.5px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  top: 1.2rem;
  right: 1.25rem;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.sidebar [aria-expanded="true"]:before,
.sidebar [data-toggle="collapse"]:not(.collapsed):before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sidebar-toggle {
  cursor: pointer;
  width: 26px;
  height: 26px;
  margin-left: 0.5rem;
}

.sidebar.toggled {
  margin-left: -250px;
}
.sidebar.toggled .sidebar-content {
  left: -250px;
}

@media (min-width: 1px) and (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }
  .sidebar .sidebar-content {
    left: -250px;
  }
  .sidebar.toggled {
    margin-left: 0;
  }
  .sidebar.toggled .sidebar-content {
    left: 0;
  }
  .sidebar-collapsed {
    margin-left: 0;
  }
  .sidebar-collapsed .sidebar-content {
    left: 0;
  }
  .sidebar-collapsed.toggled {
    margin-left: -250px;
  }
  .sidebar-collapsed.toggled .sidebar-content {
    left: -250px;
  }
}

.sidebar-header {
  background: transparent;
  color: #adb5bd;
  padding: 0.375rem 1.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: none;
}

.sidebar-badge {
  position: absolute;
  right: 15px;
  top: 13px;
}
.sidebar-dropdown .sidebar-badge {
  top: 7px;
}

.sidebar-right.toggled {
  margin-left: auto;
  margin-right: -250px;
}
.sidebar-right.toggled .sidebar-content {
  left: auto;
  right: -250px;
}

@media (min-width: 1px) and (max-width: 991.98px) {
  .sidebar-right {
    margin-left: auto;
    margin-right: -250px;
  }
  .sidebar-right .sidebar-content {
    left: auto;
    right: -250px;
  }
  .sidebar-right.toggled {
    margin-left: auto;
    margin-right: 0;
  }
  .sidebar-right.toggled .sidebar-content {
    left: auto;
    right: 0;
  }
  .sidebar-right-collapsed {
    margin-left: auto;
    margin-right: 0;
  }
  .sidebar-right-collapsed .sidebar-content {
    left: auto;
    right: 0;
  }
  .sidebar-right-collapsed.toggled {
    margin-left: auto;
    margin-right: -250px;
  }
  .sidebar-right-collapsed.toggled .sidebar-content {
    left: auto;
    right: -250px;
  }
}

.splash {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.splash .splash-icon {
  background: $dark;
  display: block;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translateX(0%) translateY(-100%);
  transform: translateX(0%) translateY(-100%);
}
.splash.active .splash-icon {
  -webkit-transform: translateX(0%) translateY(0%);
  transform: translateX(0%) translateY(0%);
}
.splash.active .splash-icon::after {
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  display: block;
  border: 5px solid $white;
  border-radius: 50%;
  content: "";
  top: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border-top-color: rgba(0, 0, 0, 0.2);
  -webkit-animation: splash-spinner 1.2s linear infinite;
  animation: splash-spinner 1.2s linear infinite;
}

@-webkit-keyframes splash-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes splash-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.card > .dataTables_wrapper .table.dataTable,
.card > .table,
.card > .table-responsive .table,
.card > .table-responsive-sm .table,
.card > .table-responsive-md .table,
.card > .table-responsive-lg .table,
.card > .table-responsive-xl .table {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  margin-bottom: 0;
}
.card > .dataTables_wrapper .table.dataTable td:first-child,
.card > .dataTables_wrapper .table.dataTable th:first-child,
.card > .table td:first-child,
.card > .table th:first-child,
.card > .table-responsive .table td:first-child,
.card > .table-responsive .table th:first-child,
.card > .table-responsive-sm .table td:first-child,
.card > .table-responsive-sm .table th:first-child,
.card > .table-responsive-md .table td:first-child,
.card > .table-responsive-md .table th:first-child,
.card > .table-responsive-lg .table td:first-child,
.card > .table-responsive-lg .table th:first-child,
.card > .table-responsive-xl .table td:first-child,
.card > .table-responsive-xl .table th:first-child {
  border-left: 0;
  padding-left: 1.25rem;
}
.card > .dataTables_wrapper .table.dataTable td:last-child,
.card > .dataTables_wrapper .table.dataTable th:last-child,
.card > .table td:last-child,
.card > .table th:last-child,
.card > .table-responsive .table td:last-child,
.card > .table-responsive .table th:last-child,
.card > .table-responsive-sm .table td:last-child,
.card > .table-responsive-sm .table th:last-child,
.card > .table-responsive-md .table td:last-child,
.card > .table-responsive-md .table th:last-child,
.card > .table-responsive-lg .table td:last-child,
.card > .table-responsive-lg .table th:last-child,
.card > .table-responsive-xl .table td:last-child,
.card > .table-responsive-xl .table th:last-child {
  border-right: 0;
  padding-right: 1.25rem;
}
.card > .dataTables_wrapper .table.dataTable tr:first-child td,
.card > .dataTables_wrapper .table.dataTable tr:first-child th,
.card > .table tr:first-child td,
.card > .table tr:first-child th,
.card > .table-responsive .table tr:first-child td,
.card > .table-responsive .table tr:first-child th,
.card > .table-responsive-sm .table tr:first-child td,
.card > .table-responsive-sm .table tr:first-child th,
.card > .table-responsive-md .table tr:first-child td,
.card > .table-responsive-md .table tr:first-child th,
.card > .table-responsive-lg .table tr:first-child td,
.card > .table-responsive-lg .table tr:first-child th,
.card > .table-responsive-xl .table tr:first-child td,
.card > .table-responsive-xl .table tr:first-child th {
  border-top: 0;
}
.card > .dataTables_wrapper .table.dataTable tr:last-child td,
.card > .table tr:last-child td,
.card > .table-responsive .table tr:last-child td,
.card > .table-responsive-sm .table tr:last-child td,
.card > .table-responsive-md .table tr:last-child td,
.card > .table-responsive-lg .table tr:last-child td,
.card > .table-responsive-xl .table tr:last-child td {
  border-bottom: 0;
}

.card .card-header + .table {
  border-top: 0;
}

.table-action a {
  color: $gray;
}
.table-action a:hover {
  color: #212529;
}

.table-action .feather {
  width: 18px;
  height: 18px;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.card > .dataTables_wrapper .table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.card > .dataTables_wrapper .dataTables_info {
  padding: 1rem 1.25rem;
}

.card > .dataTables_wrapper .dataTables_paginate {
  padding: 0.6rem 1.25rem;
}

.tab {
  margin-bottom: 2rem;
}
.tab .nav-tabs {
  border: 0;
}
.tab .nav-tabs .nav-link {
  background: transparent;
  color: $gray-dark;
  padding: 0.75rem 1rem;
  border: 0;
}
.tab .nav-tabs .nav-link.active {
  background: $white;
  color: $gray-dark;
}
.tab .nav-tabs .nav-link:hover:not(.active) {
  color: $primary;
}
.tab .nav-tabs .nav-link svg {
  width: 20px;
  height: 20px;
}
.tab .tab-content {
  background: $white;
  padding: 1.25rem;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0 0 0.2rem 0.2rem;
}
.tab .tab-content p:last-child {
  margin-bottom: 0;
}

.tab-primary .nav-tabs .nav-link.active {
  background: $primary;
  border-bottom-color: $primary;
  color: $white;
}

.tab-primary .tab-content {
  background: $primary;
  color: $white;
}

.tab-primary h1,
.tab-primary h2,
.tab-primary h3,
.tab-primary h4,
.tab-primary h5,
.tab-primary h6,
.tab-primary .h1,
.tab-primary .h2,
.tab-primary .h3,
.tab-primary .h4,
.tab-primary .h5,
.tab-primary .h6 {
  color: $white;
}

.tab-secondary .nav-tabs .nav-link.active {
  background: $secondary;
  border-bottom-color: $secondary;
  color: $white;
}

.tab-secondary .tab-content {
  background: $secondary;
  color: $white;
}

.tab-secondary h1,
.tab-secondary h2,
.tab-secondary h3,
.tab-secondary h4,
.tab-secondary h5,
.tab-secondary h6,
.tab-secondary .h1,
.tab-secondary .h2,
.tab-secondary .h3,
.tab-secondary .h4,
.tab-secondary .h5,
.tab-secondary .h6 {
  color: $white;
}

.tab-success .nav-tabs .nav-link.active {
  background: $success;
  border-bottom-color: $success;
  color: $white;
}

.tab-success .tab-content {
  background: $success;
  color: $white;
}

.tab-success h1,
.tab-success h2,
.tab-success h3,
.tab-success h4,
.tab-success h5,
.tab-success h6,
.tab-success .h1,
.tab-success .h2,
.tab-success .h3,
.tab-success .h4,
.tab-success .h5,
.tab-success .h6 {
  color: $white;
}

.tab-info .nav-tabs .nav-link.active {
  background: $info;
  border-bottom-color: $info;
  color: $white;
}

.tab-info .tab-content {
  background: $info;
  color: $white;
}

.tab-info h1,
.tab-info h2,
.tab-info h3,
.tab-info h4,
.tab-info h5,
.tab-info h6,
.tab-info .h1,
.tab-info .h2,
.tab-info .h3,
.tab-info .h4,
.tab-info .h5,
.tab-info .h6 {
  color: $white;
}

.tab-warning .nav-tabs .nav-link.active {
  background: $warning;
  border-bottom-color: $warning;
  color: $white;
}

.tab-warning .tab-content {
  background: $warning;
  color: $white;
}

.tab-warning h1,
.tab-warning h2,
.tab-warning h3,
.tab-warning h4,
.tab-warning h5,
.tab-warning h6,
.tab-warning .h1,
.tab-warning .h2,
.tab-warning .h3,
.tab-warning .h4,
.tab-warning .h5,
.tab-warning .h6 {
  color: $white;
}

.tab-danger .nav-tabs .nav-link.active {
  background: $danger;
  border-bottom-color: $danger;
  color: $white;
}

.tab-danger .tab-content {
  background: $danger;
  color: $white;
}

.tab-danger h1,
.tab-danger h2,
.tab-danger h3,
.tab-danger h4,
.tab-danger h5,
.tab-danger h6,
.tab-danger .h1,
.tab-danger .h2,
.tab-danger .h3,
.tab-danger .h4,
.tab-danger .h5,
.tab-danger .h6 {
  color: $white;
}

.tab-light .nav-tabs .nav-link.active {
  background: $light;
  border-bottom-color: $light;
  color: $white;
}

.tab-light .tab-content {
  background: $light;
  color: $white;
}

.tab-light h1,
.tab-light h2,
.tab-light h3,
.tab-light h4,
.tab-light h5,
.tab-light h6,
.tab-light .h1,
.tab-light .h2,
.tab-light .h3,
.tab-light .h4,
.tab-light .h5,
.tab-light .h6 {
  color: $white;
}

.tab-dark .nav-tabs .nav-link.active {
  background: $dark;
  border-bottom-color: $dark;
  color: $white;
}

.tab-dark .tab-content {
  background: $dark;
  color: $white;
}

.tab-dark h1,
.tab-dark h2,
.tab-dark h3,
.tab-dark h4,
.tab-dark h5,
.tab-dark h6,
.tab-dark .h1,
.tab-dark .h2,
.tab-dark .h3,
.tab-dark .h4,
.tab-dark .h5,
.tab-dark .h6 {
  color: $white;
}

.tab-tertiary .nav-tabs .nav-link.active {
  background: $tertiary;
  border-bottom-color: $tertiary;
  color: $white;
}

.tab-tertiary .tab-content {
  background: $tertiary;
  color: $white;
}

.tab-tertiary h1,
.tab-tertiary h2,
.tab-tertiary h3,
.tab-tertiary h4,
.tab-tertiary h5,
.tab-tertiary h6,
.tab-tertiary .h1,
.tab-tertiary .h2,
.tab-tertiary .h3,
.tab-tertiary .h4,
.tab-tertiary .h5,
.tab-tertiary .h6 {
  color: $white;
}

.tab-title {
  font-size: 1.125rem;
}

.tab-vertical .nav-tabs {
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.tab-vertical .nav-tabs .nav-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-vertical .tab-content {
  overflow: auto;
}

.text-sm {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.425rem;
}

b,
strong {
  font-weight: 500;
}

pre.snippet {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
}

.display-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.wrapper {
  -webkit-box-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.wrapper:before {
  width: 100%;
  height: 264px;
  position: absolute;
  top: 0;
  left: 0;
  background: $white;
  content: " ";
}

.bg-primary-light {
  background-color: #8997ed !important;
}

a.bg-primary-light:hover,
a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #5d70e6 !important;
}

.bg-secondary-light {
  background-color: #aebed6 !important;
}

a.bg-secondary-light:hover,
a.bg-secondary-light:focus,
button.bg-secondary-light:hover,
button.bg-secondary-light:focus {
  background-color: #8da3c5 !important;
}

.bg-success-light {
  background-color: #00f2b6 !important;
}

a.bg-success-light:hover,
a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #00bf8f !important;
}

.bg-info-light {
  background-color: #3fc1fe !important;
}

a.bg-info-light:hover,
a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
  background-color: #0cb1fe !important;
}

.bg-warning-light {
  background-color: #fac729 !important;
}

a.bg-warning-light:hover,
a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
  background-color: #ebb306 !important;
}

.bg-danger-light {
  background-color: #f5787d !important;
}

a.bg-danger-light:hover,
a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
  background-color: #f14850 !important;
}

.bg-light-light {
  background-color: white !important;
}

a.bg-light-light:hover,
a.bg-light-light:focus,
button.bg-light-light:hover,
button.bg-light-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark-light {
  background-color: #354067 !important;
}

a.bg-dark-light:hover,
a.bg-dark-light:focus,
button.bg-dark-light:hover,
button.bg-dark-light:focus {
  background-color: #242b46 !important;
}

.bg-tertiary-light {
  background-color: #b5bef4 !important;
}

a.bg-tertiary-light:hover,
a.bg-tertiary-light:focus,
button.bg-tertiary-light:hover,
button.bg-tertiary-light:focus {
  background-color: #8997ed !important;
}

.bg-primary-dark {
  background-color: #475de3 !important;
}

a.bg-primary-dark:hover,
a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #213ad6 !important;
}

.bg-secondary-dark {
  background-color: #7c96bc !important;
}

a.bg-secondary-dark:hover,
a.bg-secondary-dark:focus,
button.bg-secondary-dark:hover,
button.bg-secondary-dark:focus {
  background-color: #5a7bab !important;
}

.bg-success-dark {
  background-color: #00a67c !important;
}

a.bg-success-dark:hover,
a.bg-success-dark:focus,
button.bg-success-dark:hover,
button.bg-success-dark:focus {
  background-color: #007356 !important;
}

.bg-info-dark {
  background-color: #01a4f0 !important;
}

a.bg-info-dark:hover,
a.bg-info-dark:focus,
button.bg-info-dark:hover,
button.bg-info-dark:focus {
  background-color: #0181bd !important;
}

.bg-warning-dark {
  background-color: #d2a005 !important;
}

a.bg-warning-dark:hover,
a.bg-warning-dark:focus,
button.bg-warning-dark:hover,
button.bg-warning-dark:focus {
  background-color: #a07a04 !important;
}

.bg-danger-dark {
  background-color: #ef3039 !important;
}

a.bg-danger-dark:hover,
a.bg-danger-dark:focus,
button.bg-danger-dark:hover,
button.bg-danger-dark:focus {
  background-color: #dc111a !important;
}

.bg-light-dark {
  background-color: #d3d9df !important;
}

a.bg-light-dark:hover,
a.bg-light-dark:focus,
button.bg-light-dark:hover,
button.bg-light-dark:focus {
  background-color: #b5bfc9 !important;
}

.bg-dark-dark {
  background-color: #1b2035 !important;
}

a.bg-dark-dark:hover,
a.bg-dark-dark:focus,
button.bg-dark-dark:hover,
button.bg-dark-dark:focus {
  background-color: #0a0c13 !important;
}

.bg-tertiary-dark {
  background-color: #7384ea !important;
}

a.bg-tertiary-dark:hover,
a.bg-tertiary-dark:focus,
button.bg-tertiary-dark:hover,
button.bg-tertiary-dark:focus {
  background-color: #475de3 !important;
}

.cursor-grab {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-visible {
  overflow: visible;
}

.absolute-top {
  position: absolute;
  left: 0;
  top: 0;
}

.min-vw-25 {
  min-width: 25vw !important;
}

.min-vw-50 {
  min-width: 50vw !important;
}

.min-vw-75 {
  min-width: 75vw !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.vw-25 {
  width: 25vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.gradient {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 18%, #f4f4f4 38%, #fafafa 54%);
  background-size: 500px 250px;
  
  position: relative;
  
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -500px 0
  }
  100%{
      background-position: 500px 0
  }
}
